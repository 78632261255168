body.WebKit {
  /* Safe area insets for devices with notches */
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /* Allow vertical scrolling, hide horizontal scroll */
  overflow-y: auto !important;
  overflow-x: hidden !important;

  /* Hide scrollbar across browsers */
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE/Edge */

}

body.WebKit .mobileheader{
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

body.WebKit .mobile-menu-bottom {
  padding-bottom: env(safe-area-inset-bottom)   ;
}

/* Hide scrollbar in WebKit (Chrome, Safari, Edge) */
body.WebKit::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

body.WebKit .product-list-rightwrap-container {
  height: 80%;
  overflow: hidden;
}

body.WebKit .product-listing {
  height: 100%;
}

body.WebKit .productwrap-inner {
  height: 100%;
}

body.WebKit .logo-container{
  display: none;
}

body.WebKit .mainwrapper {
  margin-top: 5rem;
}


body.WebKit .product-detail {
  margin-bottom: calc(env(safe-area-inset-bottom) + 5rem);
}

body.WebKit.login-container {
  top: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

body.WebKit .login-form{
  margin-bottom: 5rem;
}

body.WebKit  .p-popup {
  margin-bottom: calc(5rem + env(safe-area-inset-bottom));
}

body.WebKit .toast{
  margin-top: env(safe-area-inset-top) !important;
}

body.WebKit .merchant-identity{
  padding-top: env(safe-area-inset-top) !important;
}

body.WebKit .razorpay-container{
    /* For top/bottom safe area */
    margin-top: 5rem;
  
    /* This ensures the wrapper itself stays within the safe area */
    /* box-sizing: border-box; */
    width: 100%;
    height: 80vh !important;
    overflow: scroll;
    /* or use a calc() approach if you want a precise height */
}

 body.WebKit .razorpay-container iframe {
  display: block;
  width: 100%;
  height: 80vh !important;
  border: 0;
}
body.WebKit .product-detail .img-wrap img {
  min-height: 320px;
  height: auto;
}
body.WebKit .imageWrapper .leftarrow, 
body.WebKit .imageWrapper .rightarrow  {
  top: 40%;
}
