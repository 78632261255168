body.tablet .login-container{
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

body.tablet {
  /* Safe area insets for devices with notches */
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-left: env(safe-area-inset-left) !important;
  padding-right: env(safe-area-inset-right) !important;

  /* Allow vertical scrolling, hide horizontal scroll */
  overflow-y: auto !important;
  overflow-x: hidden !important;

  /* Hide scrollbar across browsers */
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE/Edge */

  width: 100% !important;
  height: 100% !important;
  /* background-color: #fff; */
}



/* Hide scrollbar in WebKit (Chrome, Safari, Edge) */
body.tablet::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.tablet .mainwrapper {
  margin-top: 4rem;
}

.tablet .wrap-productlist,
.tablet .product-detail-page,
.tablet .view-cart-container,
.tablet .account-container {
  margin-top: 4rem;
}

.tablet .view-cart-container
{
  margin-top: 4rem;
  padding-top: 0;
}

.tablet .topfooter .rightwrap,
.tablet .socialtitle {
  display: none;
}
.tablet #navbar-right svg {
  width: 40px;
}
.tablet .socialwrap svg {
  width: 22px;
  height: 22px;
}
.tablet .deliveryOption {
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
}
.tablet .deliveryOption .steps {
  width: 48%;
  font-size: 14px;
  line-height: 20px;
}
.tablet .deliveryOption .steps svg {
  width: 54px;
  height: 54px;
}
.tablet .wholesale-content,
.tablet .bottomfooter {
  flex-direction: column;
}
.tablet .bottomfooter,
.tablet .topfooter {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.tablet .bottomfooter {
  margin-bottom: 82px;
}
.tablet .topfooter .leftwrap svg {
  margin: 0 auto 1rem;
}
.tablet .social-links {
  margin-bottom: 0.5rem;
}
.tablet .growsection .titletext,
.tablet .contenttitle {
  font-size: 14px;
  line-height: 18px;
  padding: 0 0 0.7rem;
}
.tablet .wholesale-content {
  margin-bottom: 0;
}
.tablet .registercard-wrapper .ltext-Wrap p,
.tablet .registercard-wrapper .rt-item p,
.tablet .ourstory-page .innerwrapper p,
.tablet .cat-title,
.tablet .growsection .smalltext,
.tablet .ContentWrap {
  font-size: 14px;
  line-height: 20px;
}
.tablet .wrapper-middle-section {
  border-radius: 35px;
  margin-bottom: 0;
  padding-top: 0;
}
.tablet .category-text {
  max-width: 4.35rem;
}
.tablet .sub-item {
  margin-left: 1rem;
}
.tablet .wrapNumbers {
  justify-content: space-around;
}
.tablet .wrapNumbers .number {
  font-size: 35px;
  line-height: 27px;
}
.tablet .p-accordion-header-text,
.tablet .Itemswrap .item-title,
.tablet .wrapNumbers .text {
  font-size: 14px;
  line-height: 20px;
  padding: 0.5rem 0;
}
.tablet .imgewrapper img.h-16.object-contain {
  height: 7rem;
}
.tablet .Itemswrap {
  flex-wrap: wrap;
}
.tablet .wrapNumbers .noItem,
.tablet .Itemswrap .item {
  max-width: 46%;
  width: 46%;
}
.tablet .Itemswrap .item .item-title {
  margin-bottom: 0;
}
.tablet .Itemswrap .item svg {
  height: 118px;
  width: 118px;
}
.tablet .wrapcategory .items {
  margin-bottom: 0.7rem;
}
.tablet .wrapcategory .items div {
  width: 100%;
}
.tablet .carousal {
  /* height: 130px; */
  padding-block: 0;
}
.tablet .carousal button span {
  width: 20px;
  height: 20px;
}
.tablet .carousal button span svg {
  height: 10px;
  color: #000;
}
.tablet .carousal img {
  border-radius: 22px;
}
.tablet .header {
  margin-bottom: 0.7rem;
}
.tablet .header .nav-innerwrap {
  flex-wrap: wrap;
}
.tablet .logo-wrap {
  width: 100%;
}
.tablet .logo-wrap .logo-container {
  margin: 0 auto 0.7rem;
}
.tablet #search-bar {
  width: 70%;
}
.tablet .ourstory-page .innerwrapper {
  flex-direction: column;
}
.tablet .ourstory-page .innerwrapper p {
  text-align: center;
  line-height: 14px;
}
.tablet .cardwrapper .custom-container {
  flex-direction: column;
  line-height: 14px;
}
.tablet .cardwrapper .custom-container svg {
  width: 104px;
  height: 104px;
}
.tablet .cardwrapper .custom-container .ourcard {
  margin-block: 1rem;
}
.tablet .wrap-helpsupport-page,
.tablet .wrap-contactus-page,
.tablet .wrap-faq-page,
.tablet .wrap-delivery-page {
  border-radius: 5px;
  border: 1px solid #efeff1;
}
.tablet .product-detail .detail-text,
.tablet .wrap-delivery-page .subtitle {
  font-size: 14px;
  line-height: 18px;
}
.tablet .p-accordion .p-accordion-content,
.tablet .staticpage p,
.tablet .staticpage div {
  font-size: 12px;
  line-height: 16px;
}
.tablet .p-accordion .p-accordion-header-text {
  padding: 0;
  max-width: 85%;
}
.tablet .p-accordion .p-accordion-header-link,
.tablet .p-accordion .p-accordion-content {
  padding: 0.5rem;
}
.tablet .wrap-contactus-page .card-textbg {
  max-width: 70%;
  margin: 0 0 0.7rem;
}
.tablet .wrap-contactus-page p.font-bold {
  margin-bottom: 0.2rem;
}
.tablet .tabcontent p {
  line-height: 16px;
}
.tablet .tabcontent p.pt-6 {
  padding-top: 0;
  margin-bottom: 0.5rem;
}
.tablet .tabtitle button {
  font-size: 10px;
  line-height: 12px;
  padding: 0.3rem;
  text-align: left;
}
.tablet .tabtitle button svg {
  width: 15px;
  height: 17px;
}
.tablet .tabtitle {
  padding-top: 0.5rem;
}
.tablet .tabcontent .innertitle {
  font-size: 14px;
  line-height: 18px;
}
.tablet .p-sidebar-right .p-sidebar {
  width: 100%;
  height: 100%;
}
.tablet .faqwrapper h6,
.tablet .staticpage h6 {
  background: #fff;
  margin: 0 -15px 1rem;
  padding-inline: 1rem;
}
.tablet .registercard-wrapper {
  border-radius: 22px;
  margin-inline: 0;
  padding: 1rem;
  min-height: 150px;
}
.tablet .registercard-wrapper .rtext-Wrap svg {
  width: 45px;
  height: 45px;
}
.tablet .registercard-wrapper .rtext-Wrap svg {
  width: 45px;
  height: 45px;
}
.tablet .add-btn {
  font-size: 12px;
  line-height: 14px;
  height: 17px;
  min-width: 48px;
}
/* .tablet .product-listing .addButton .add-btn,
.tablet .product-listing #quantity-counter .countBtn {
    height: 24px;
    width: 85px;
    min-width: 85px;
}     */
.tablet .seller-itemwrap #quantity-counter,
.tablet .product-listing #quantity-counter {
  width: auto;
  max-width: 45%;
}
.tablet .seller-itemwrap .add-btn.btn-added {
  padding-inline: 0.35rem;
}
.tablet .countBtn svg,
.tablet .productcard svg {
  height: 7px;
  width: 7px;
}
.tablet .countBtn {
  height: 24px;
  width: auto;
  padding: 0;
}
.tablet .product-detail-text .add-btn {
  min-width: 122px;
  height: 43px;
}
.tablet .product-detail-text .add-btn svg {
  height: 12px;
  width: 12px;
}
.tablet .product-detail-text .countBtn {
  width: 116px;
  height: 43px;
}
.tablet .product-detail-text .countBtn svg {
  width: 10px;
  height: 10px;
}
.tablet .product-detail-text .countBtn button {
  height: 43px;
  width: 40px !important;
}
.tablet .countBtn button {
  height: 24px;
  width: 20px;
}
.tablet .product-info {
  padding-inline: 1rem;
}
.tablet div#product-image {
  padding: 0;
}
.tablet #product-image img {
  width: auto;
  height: auto;
}
.tablet .p-sidebar .p-sidebar-content,
.tablet .p-sidebar .p-sidebar-header {
  padding-inline: 1rem;
}
.tablet .cart-innerwrap tr {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tablet .cart-innerwrap tr td {
  padding: 0.5rem;
}
.cart-slidesidebar.p-sidebar tr .delete-btn svg,
.tablet .delete-btn svg {
  width: 11px;
  height: 15px;
}
.tablet .p-sidebar .titlewrap svg {
  width: 21px;
  height: 21px;
}
.tablet .p-sidebar .emptycart svg {
  width: 183px;
  height: 154px;
}
.tablet .sidebar-filter-wrapper {
  width: 12rem;
  margin-left: -24px;
}
.tablet .sidebar-filter-wrapper .innerfilter {
  width: 12rem;
  border-radius: 0;
  height: 100%;
}
.tablet .sidebar-filter-wrapper .accorditem {
  line-height: 10px;
  margin-inline: 0;
}
.tablet .sidebar-filter-wrapper .accorditem label {
  padding-left: 1rem;
}
.tablet .sidebar-filter-wrapper .maintitle {
  line-height: 14px;
  text-align: left;
}
.tablet .sidebar-filter-wrapper .listitem {
  padding-block: 0;
}
.tablet .sidebar-filter-wrapper .btntitle {
  padding-inline: 0;
}
.tablet .filter-button button {
  padding-inline: 0.5rem;
}
.tablet .filter-button button svg {
  height: 10px;
  width: 10px;
}
.tablet .product-detail {
  flex-direction: column;
}
.tablet .product-detail .imageslide {
  max-width: 100%;
  flex-basis: 100%;
  margin: 0 auto 1rem;
}
.tablet .product-detail .slidebutton .lefticon,
.tablet .product-detail .slidebutton .righticon {
  width: 22px;
  height: 22px;
  top: 38%;
}
.tablet .product-detail .product-text-wrap {
  max-width: 100%;
}
.tablet .grid-items .grid-item {
  width: 48.5%;
  margin: auto;
}
.tablet .product-listing {
  /* height: calc(100vh - 400px); */
  overflow-y: scroll;
  overflow-x: hidden;
}
.tablet .productwrap-inner {
  height: calc(100vh - 12rem);
}
.tablet .p-popup {
  margin-bottom: 5rem;
  width: 100vw !important;
  height: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tablet.cart-container .p-dialog-mask {
  height: calc(100vh - 82px) !important;
}
.tablet .l-button button {
  height: 52px;
  border-radius: 4px;
  min-width: 170px;
}
.tablet .brand-popup {
  width: 100vw;
  height: 40vh;
  /* bottom: 7vh; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
}
.tablet .p-dialog-mask {
  align-items: flex-end !important;
  height: 100vh !important;
}
.tablet .brand-popup .brand-item {
  margin-bottom: 0;
}
.tablet .brand-popup .brand-list-item label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.tablet .brand-popup .brand-list-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
.tablet .p-popup .p-dialog-title {
  font-size: 18px;
}
.tablet .sortoption label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.tablet .mobile-menu-bottom {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9992;
  box-shadow: 0px 10px 20px 10px #cccccc;
  background: #fff; /* Ensure the background is solid */
  padding-bottom: env(
    safe-area-inset-bottom,
    20px
  ); /* Add padding for safe area */
}
.tablet .mobile-menu-bottom li svg {
  margin: auto;
}
.tablet .main-content {
  padding-bottom: calc(
    60px + env(safe-area-inset-bottom, 20px)
  ); /* Adjust height dynamically */
}
.tablet .mobile-menu-bottom a svg {
  margin: auto;
  height: 30px;
}
.tablet .mobile-menu-bottom a.active {
  color: #ca2f2e;
}
.tablet .mobile-menu-bottom .home a.active svg > g > path {
  stroke: #ca2f2e;
}

.tablet .mobile-menu-bottom .shop a.active svg > g {
  fill: #ca2f2e;
}

.tablet .mobile-menu-bottom .order a.active svg > path {
  stroke: #ca2f2e;
  fill: #ca2f2e;
}

.tablet .mobile-menu-bottom .account a.active svg path {
  /* stroke: #CA2F2E; */
  fill: #ca2f2e;
}

.tablet .mobile-menu-bottom .help a.active svg path {
  /* stroke: #CA2F2E; */
  fill: #ca2f2e;
}

.tablet .order-container {
  padding-inline: 0;
  padding-top: 0;
}
.tablet .order-container .titleWrapper {
  background: #fff;
  padding: 1.32rem 1rem;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.tablet .order-container .titleWrapper p {
  margin-bottom: 0;
  /* padding-top: 1.32rem; */
}
.tablet .p-rating .p-rating-item .p-rating-icon.p-icon {
  width: 10px;
  height: 12px;
}
.tablet .order-card {
  line-height: 12px;
}
.tablet p.ratingtext {
  margin: 0;
}
.tablet .btnfilter svg {
  width: 8px;
  height: 8px;
}
.tablet .btn-address {
  line-height: 14px;
}
.tablet .btn-address svg {
  width: 15px;
  height: 15px;
}
.tablet .profile-wrapper .field-group label {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0.3rem;
}
.tablet .profile-wrapper .field-group {
  margin-bottom: 1rem;
}
.tablet .address-card,
.tablet .password-card,
.tablet .profile-info-card {
  border-radius: 5px;
}
.tablet .address-detail {
  font-size: 14px;
  line-height: 18px;
}
.tablet .editbtn {
  font-size: 14px;
  line-height: 18px;
}
.tablet .editbtn svg {
  height: 12px;
  width: 12px;
}
.tablet .items-totalprice span {
  font-size: 14px;
  line-height: 18px;
}
.tablet .cart-item-table tr td:first-child,
.tablet .p-sidebar-content tr td:first-child {
  width: 60%;
}
.tablet .cart-item-table tr #productimage,
.tablet .p-sidebar-content tr #productimage {
  flex: 0 0 54px;
  padding: 0;
}
.tablet .cart-item-table tr #productimage img,
.tablet .p-sidebar-content tr #productimage img {
  border-radius: 10px;
}
.tablet .cart-item-table tr th {
  font-size: 12px;
  line-height: 14px;
}
.tablet .cart-item-table tr td,
.tablet .cart-item-table tr th {
  padding-inline: 1rem;
}
.tablet .cart-slidesidebar .p-sidebar-content {
  background: #f6f5ff;
}
.tablet .cart-slidesidebar .item-counts {
  margin: 0 0 1rem;
  padding-inline: 0.5rem;
}
.tablet .socialwrap + p {
  margin-bottom: 0;
}
.tablet .product-list-rightwrap {
  width: calc(100% - 8rem);
}
.tablet .fliter-dropdown .filter-menuitem {
  font-size: 10px;
  line-height: 14px;
  padding-block: 0.2rem;
}
.tablet .filtermenu {
  min-width: auto;
}
.tablet .imageWrapper .leftarrow {
  left: 0;
}
.tablet .imageWrapper .rightarrow {
  right: 0;
}
.tablet .imageWrapper .leftarrow,
.tablet .imageWrapper .rightarrow {
  width: 24px;
  height: 24px;
  top: 45%;
}
.tablet .imageWrapper .leftarrow svg,
.tablet .imageWrapper .rightarrow svg {
  height: inherit;
}
.tablet .imageWrapper .img-wrap {
  width: 90vw;
  margin-inline: auto;
  background: transparent;
}
/* .tablet .imageWrapper .p-img {
    height: 200px;
} */
.tablet .p-sidebar-right .p-sidebar.cart-slidesidebar {
  height: calc(100vh - 80px);
  top: -41px;
}
.tablet .cart-innerwrap {
  min-height: calc(100vh - 260px);
}
.tablet .cartwrapper .itemwrap,
.tablet .cartwrapper .totalwrap {
  max-width: 100%;
  flex-basis: 100%;
}
.tablet .cartwrapper .itemwrap {
  margin-bottom: 0;
  padding-bottom: 0;
}
.tablet .cartwrapper .p-accordion-content .itemwrap {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  border-bottom: 1px solid #efeff1;
}
.tablet .cartwrapper .p-accordion-content .itemwrap:last-child {
  border-bottom: none;
}

.tablet .textwrap svg {
  width: 14px;
  height: 14px;
}
.tablet .btn-green {
  font-size: 14px;
  line-height: 17px;
  height: 55px;
}
.tablet.contact-us-container h6,
.tablet.help-support-container h6 {
  width: 100%;
}
.tablet.contact-us-container h6,
.tablet.help-support-container h6,
.tablet.orders-container .titleWrapper,
.tablet .mobileheader {
  position: fixed;
  top: 0;
  padding-top: env(safe-area-inset-top) !important;
  background: #fff;
  z-index: 1001;
  padding-bottom: 0;
}
.tablet .mobile-header {
  flex: auto;
  display: flex;
  align-items: center;
}
.tablet.contact-us-container .wrap-contactus-page,
.tablet.help-support-container .wrap-helpsupport-page,
.tablet.orders-container,
.tablet.product-container .product-detail-page,
.tablet.products-container .wrap-productlist,
.tablet {
  /* margin-top: 4rem !important; */
  margin-bottom: 5rem;
}

/* .tablet.home-container {
  margin-top: 8rem;
  margin-bottom: 4rem;
} */

.tablet.login-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.tablet.product-container .product-detail-page {
  padding-top: 0;
  margin-bottom: 0;
}

.tablet .back-icon {
  padding: 0.2rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ececec;
  width: 10%;
  height: 42px;
  align-content: center;
  align-items: center;
  text-align: -webkit-center;
}
.search-box {
  width: 70%;
}
.title {
  width: 70%;
  align-content: center;
  padding-bottom: 0.5rem;
  font: caption;
}
.tablet.products-container #search-bar,
.tablet.product-container #search-bar {
  width: 70%;
}
.tablet.help-support-container .wrap-helpsupport-page,
.tablet.product-container .wholesalesection {
  margin-bottom: 82px;
}
.tablet.account-container {
  height: calc(100vh - 80px);
  background: #fff;
  /* padding: 1rem; */
}
.tablet .cart-slidesidebar.p-sidebar tr .price .text-black {
  font-size: 12px;
}
.tablet .cart-slidesidebar.p-sidebar tr .countBtn {
  height: 24px;
}
.tablet .tabtitle a {
  font-size: 12px;
  line-height: 14px;
  padding-inline: 5px;
}
.tablet .tabtitle a svg {
  width: 20px;
  height: 20px;
}
.tablet .parent-item .icon svg {
  height: 10px;
  width: 12px;
}
.tablet .breadcrumb svg {
  width: 10px;
  height: 10px;
}

.tablet .p-popup .p-dialog-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tablet .wrap-account-mobile {
  height: 100%;
  margin-top: 4rem;
}
.tablet #navbar-right {
  width: 20%;
}
.tablet .product-info-wrap {
  flex-direction: column;
  align-items: flex-start;
}
.tablet .product-info-wrap .btnwrap {
  margin-top: 5px;
}
.tablet .testimonials-section .btn {
  width: 28px;
  height: 28px;
  top: 62%;
}
.tablet .testimonials-section .embla__next {
  right: 0;
}
.tablet .p-breadcrumb {
  padding: 0;
  margin-block: 0.5rem;
}
.tablet #pagination-ribbon ul {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tablet .logo-container {
  padding: 0;
  margin: auto auto 0.5rem;
}
.tablet .product-listing #product-image img {
  min-height: 120px;
}
.tablet .saler-card #product-image img {
  height: 120px;
}

.tablet .view-cart-container {
  margin-bottom: 4rem;
  /* margin-top: 0 !important; */
}

.tablet .wrapEditprofile {
  padding: 0;
}
.tablet .order-detail{
  margin-top: 0 !important;
}
.tablet .faqwrapper,
.tablet .profile-pages,
.tablet .staticpage{
  margin-top: 0 !important;
  padding-top: 3rem;
}

.tablet .privacy-wrapper.staticpage,
.tablet .returnpolicy-wrapper.staticpage,
.tablet .contact-wrapper.staticpage,
.tablet .terms-wrapper.staticpage {
  padding-top: 0 !important;
}
.tablet .wrap-delivery-page {
  margin-bottom: 6rem;
}

.tablet .login-form{
  margin-bottom: 4rem;
}
.tablet .topfooter .leftwrap {
  width: 80%;
  margin: auto;
}
.tablet .wrap-delivery-page,
.tablet .wrap-helpsupport-page {
  margin-top: .5rem;
}
.tablet .cart-count {
  top: 2px;
}
.tablet .login-bg {
  background-size: cover !important;
}
.tablet .card.hidden {
  display: none;
}
.tablet .view-cart-container .p-accordion-header-text p {
  font-size: 16px;
}
.tablet.orders-container .order-card-item {
  width: 48.5%;
}
.tablet.orders-container .order-card-item .order-card .color-lightblack.capitalize {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-right: .25rem;
}
.tablet.orders-container .order-card-item .order-card .ml-2 {
  margin-left: 0;
}
.tablet .titleWrapper .md\:text-3xl,
.tablet .order-detail .md\:text-3xl {
  font-size: 20px;
  line-height: 24px;
}