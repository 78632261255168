body.mobile .login-container {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}

body.mobile {
  /* Allow vertical scrolling, hide horizontal scroll */
  overflow-y: auto;
  overflow-x: hidden;

  /* Hide scrollbar across browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  width: 100%;
  height: 100%;
  /* background-color: #fff; */
}

/* Hide scrollbar in WebKit (Chrome, Safari, Edge) */
body.mobile::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.mobile .mainwrapper {
  margin-top: 9rem;
}

.mobile .wrap-productlist,
/* .mobile .product-detail-page, */
.mobile .view-cart-container,
.mobile .account-container {
  margin-top: 5rem;
  margin-bottom: 4rem;
}


.mobile .product-detail-page{
  margin-top: 5rem;
}

.mobile .view-cart-container {
  margin-top: 4rem;
  padding-top: 0;
}

.mobile .topfooter .rightwrap,
.mobile .socialtitle {
  display: none;
}
.mobile #navbar-right svg {
  width: 40px;
}
.mobile .socialwrap svg {
  width: 22px;
  height: 22px;
}
.mobile .deliveryOption {
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;
}
.mobile .deliveryOption .steps {
  width: 48%;
  font-size: 10px;
  line-height: 14px;
}
.mobile .deliveryOption .steps svg {
  width: 54px;
  height: 54px;
}
.mobile .wholesale-content,
.mobile .bottomfooter {
  flex-direction: column;
}
.mobile .bottomfooter,
.mobile .topfooter {
  text-align: center;
  font-size: 10px;
  line-height: 14px;
}
.mobile .bottomfooter {
  margin-bottom: 82px;
}
.mobile .topfooter .leftwrap svg {
  margin: 0 auto 1rem;
}
.mobile .social-links {
  margin-bottom: 0.5rem;
}
.mobile .growsection .titletext,
.mobile .contenttitle {
  font-size: 14px;
  line-height: 18px;
  padding: 0 0 0.7rem;
}
.mobile .wholesale-content {
  margin-bottom: 0;
}
.mobile .registercard-wrapper .ltext-Wrap p,
.mobile .registercard-wrapper .rt-item p,
.mobile .ourstory-page .innerwrapper p,
.mobile .cat-title,
.mobile .growsection .smalltext,
.mobile .ContentWrap {
  font-size: 10px;
  line-height: 12px;
}
.mobile .wrapper-middle-section {
  border-radius: 35px;
  margin-bottom: 0;
  padding-top: 0;
}
.mobile .category-text {
  max-width: 4.35rem;
}
.mobile .sub-item {
  margin-left: 1rem;
}
.mobile .wrapNumbers {
  justify-content: space-around;
}
.mobile .wrapNumbers .number {
  font-size: 35px;
  line-height: 27px;
}
.mobile .p-accordion-header-text,
.mobile .Itemswrap .item-title,
.mobile .wrapNumbers .text {
  font-size: 10px;
  line-height: 14px;
  padding: 0.5rem 0;
}
.mobile .imgewrapper img.h-16.object-contain {
  height: 4rem;
}
.mobile .Itemswrap {
  flex-wrap: wrap;
}
.mobile .wrapNumbers .noItem,
.mobile .Itemswrap .item {
  max-width: 46%;
  width: 46%;
}
.mobile .Itemswrap .item .item-title {
  margin-bottom: 0;
}
.mobile .Itemswrap .item svg {
  height: 118px;
  width: 118px;
}
.mobile .wrapcategory .items {
  margin-bottom: 0.7rem;
}
.mobile .wrapcategory .items div {
  width: 100%;
}
.mobile .carousal {
  height: 130px;
  padding-block: 0;
}
.mobile .carousal button span {
  width: 20px;
  height: 20px;
}
.mobile .carousal button span svg {
  height: 10px;
  color: #000;
}
.mobile .carousal img {
  border-radius: 22px;
}
/* .mobile .header {
  margin-bottom: 0.7rem;
} */
.mobile .header .nav-innerwrap {
  flex-wrap: wrap;
  padding-top: 0.7rem;
}
.mobile .logo-wrap {
  width: 100%;
}
.mobile .logo-wrap .logo-container {
  margin: 0 auto 0.7rem;
}
.mobile #search-bar {
  width: 70%;
}
.mobile .ourstory-page .innerwrapper {
  flex-direction: column;
}
.mobile .ourstory-page .innerwrapper p {
  text-align: center;
  line-height: 14px;
}
.mobile .cardwrapper .custom-container {
  flex-direction: column;
  line-height: 14px;
}
.mobile .cardwrapper .custom-container svg {
  width: 104px;
  height: 104px;
}
.mobile .cardwrapper .custom-container .ourcard {
  margin-block: 1rem;
}
.mobile .wrap-helpsupport-page,
.mobile .wrap-contactus-page,
.mobile .wrap-faq-page,
.mobile .wrap-delivery-page {
  border-radius: 5px;
  border: 1px solid #efeff1;
}
.mobile .product-detail .detail-text,
.mobile .wrap-delivery-page .subtitle {
  font-size: 10px;
  line-height: 14px;
}
.mobile .p-accordion .p-accordion-content,
.mobile .staticpage p,
.mobile .staticpage div {
  font-size: 8px;
  line-height: 12px;
}
.mobile .p-accordion .p-accordion-header-text {
  padding: 0;
  max-width: 85%;
}
.mobile .p-accordion .p-accordion-header-link,
.mobile .p-accordion .p-accordion-content {
  padding: 1rem 0.5rem;
}
.mobile .wrap-contactus-page .card-textbg {
  max-width: 70%;
  margin: 0 0 0.7rem;
}
.mobile .wrap-contactus-page p.font-bold {
  margin-bottom: 0.2rem;
}
.mobile .tabcontent p {
  line-height: 12px;
}
.mobile .tabcontent p.pt-6 {
  padding-top: 0;
  margin-bottom: 0.5rem;
}
.mobile .tabtitle button {
  font-size: 10px;
  line-height: 12px;
  padding: 0.3rem;
  text-align: left;
}
.mobile .tabtitle button svg {
  width: 15px;
  height: 17px;
}
.mobile .tabtitle {
  padding-top: 0.5rem;
}
.mobile .tabcontent .innertitle {
  font-size: 10px;
  line-height: 14px;
}
.mobile .p-sidebar-right .p-sidebar {
  width: 100%;
  height: 100%;
}
.mobile .faqwrapper h6,
.mobile .staticpage h6 {
  background: #fff;
  margin: 0 -15px 1rem;
  padding-inline: 1rem;
}
.mobile .registercard-wrapper {
  border-radius: 22px;
  margin-inline: 0;
  padding: 1rem;
  min-height: 150px;
}
.mobile .registercard-wrapper .rtext-Wrap svg {
  width: 45px;
  height: 45px;
}
.mobile .registercard-wrapper .rtext-Wrap svg {
  width: 45px;
  height: 45px;
}
.mobile .add-btn {
  font-size: 12px;
  line-height: 14px;
  height: 17px;
  min-width: 48px;
}
/* .mobile .product-listing .addButton .add-btn,
.mobile .product-listing #quantity-counter .countBtn {
    height: 24px;
    width: 85px;
    min-width: 85px;
}     */
.mobile .seller-itemwrap #quantity-counter,
.mobile .product-listing #quantity-counter {
  width: auto;
  max-width: 45%;
}
.mobile .seller-itemwrap .add-btn.btn-added {
  padding-inline: 0.35rem;
}
.mobile .countBtn svg,
.mobile .productcard svg {
  height: 7px;
  width: 7px;
}
.mobile .countBtn {
  height: 24px;
  width: auto;
  padding: 0;
}
.mobile .product-detail-text .add-btn {
  min-width: 122px;
  height: 43px;
}
.mobile .product-detail-text .add-btn svg {
  height: 12px;
  width: 12px;
}
.mobile .product-detail-text .countBtn {
  width: 116px;
  height: 43px;
}
.mobile .product-detail-text .countBtn svg {
  width: 10px;
  height: 10px;
}
.mobile .product-detail-text .countBtn button {
  height: 43px;
  width: 40px !important;
}
.mobile .countBtn button {
  height: 24px;
  width: 20px;
}
.mobile .product-info {
  padding-inline: 0.5rem;
}
.mobile div#product-image {
  padding: 0;
}
.mobile #product-image img {
  width: auto;
  height: auto;
}
.mobile .p-sidebar .p-sidebar-content,
.mobile .p-sidebar .p-sidebar-header {
  padding-inline: 1rem;
}
.mobile .cart-innerwrap tr {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile .cart-innerwrap tr td {
  padding: 0.5rem;
}
.cart-slidesidebar.p-sidebar tr .delete-btn svg,
.mobile .delete-btn svg {
  width: 11px;
  height: 15px;
}
.mobile .p-sidebar .titlewrap svg {
  width: 21px;
  height: 21px;
}
.mobile .p-sidebar .emptycart svg {
  width: 183px;
  height: 154px;
}
.mobile .sidebar-filter-wrapper {
  width: 8rem;
  margin-left: -15px;
}
.mobile .sidebar-filter-wrapper .innerfilter {
  width: 8rem;
  border-radius: 0;
  height: 100%;
}
.mobile .sidebar-filter-wrapper .accorditem {
  line-height: 10px;
  margin-inline: 0;
}
.mobile .sidebar-filter-wrapper .accorditem label {
  padding-left: 1rem;
}
.mobile .sidebar-filter-wrapper .maintitle {
  line-height: 14px;
  text-align: left;
}
.mobile .sidebar-filter-wrapper .listitem {
  padding-block: 0;
}
.mobile .sidebar-filter-wrapper .btntitle {
  padding-inline: 0;
}
.mobile .filter-button button {
  padding-inline: 0.5rem;
}
.mobile .filter-button button svg {
  height: 10px;
  width: 10px;
}
.mobile .product-detail {
  flex-direction: column;
  margin-bottom: 4rem;
}
.mobile .product-detail .imageslide {
  max-width: 100%;
  flex-basis: 100%;
  margin: 0 auto 1rem;
}
.mobile .product-detail .slidebutton .lefticon,
.mobile .product-detail .slidebutton .righticon {
  width: 22px;
  height: 22px;
  top: 38%;
}
.mobile .product-detail .product-text-wrap {
  max-width: 100%;
}
.mobile .grid-items .grid-item {
  width: 100%;
  margin: auto;
}
.mobile .product-list-rightwrap-container {
  height: 94%;
}

.mobile .product-listing {
  height: 100%;
  /* height: calc(100vh - 400px); */
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile .productwrap-inner {
  /* height: calc(100vh - 12rem); */
  height: 100%;
}

.mobile .p-popup {
  margin-bottom: 5rem;
  width: 100vw !important;
  height: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* .mobile.cart-container .p-dialog-mask {
  height: calc(100vh - 82px) !important;
} */
.mobile .l-button button {
  height: 52px;
  border-radius: 4px;
  min-width: 170px;
}
.mobile .brand-popup {
  width: 100vw;
  height: 40vh;
  /* bottom: 7vh; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
}
.mobile .p-dialog-mask {
  align-items: flex-end !important;
  height: 100vh !important;
  padding-top: 5rem;
}
.mobile .brand-popup .brand-item {
  margin-bottom: 0;
}
.mobile .brand-popup .brand-list-item label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.mobile .brand-popup .brand-list-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
.mobile .p-popup .p-dialog-title {
  font-size: 18px;
}
.mobile .sortoption label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.mobile .mobile-menu-bottom {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9992;
  box-shadow: 0px 10px 20px 10px #cccccc;
  background: #fff; /* Ensure the background is solid */
  /* padding-bottom: 20px */
}
.mobile .main-content {
  padding-bottom: 80px;
}
.mobile .mobile-menu-bottom a svg {
  margin: auto;
  height: 30px;
}
.mobile .mobile-menu-bottom a.active {
  color: #ca2f2e;
}
.mobile .mobile-menu-bottom .home a.active svg > g > path {
  stroke: #ca2f2e;
}

.mobile .mobile-menu-bottom .shop a.active svg > g {
  fill: #ca2f2e;
}

.mobile .mobile-menu-bottom .order a.active svg > path {
  stroke: #ca2f2e;
  fill: #ca2f2e;
}

.mobile .mobile-menu-bottom .account a.active svg path {
  /* stroke: #CA2F2E; */
  fill: #ca2f2e;
}

.mobile .mobile-menu-bottom .help a.active svg path {
  /* stroke: #CA2F2E; */
  fill: #ca2f2e;
}

.mobile .order-container {
  padding-inline: 0;
  padding-top: 0;
}
.mobile .order-container .titleWrapper {
  background: #fff;
  padding: 1.32rem 1rem;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.mobile .order-container .titleWrapper p {
  margin-bottom: 0;
  /* padding-top: 1.32rem; */
}
.mobile .p-rating .p-rating-item .p-rating-icon.p-icon {
  width: 10px;
  height: 12px;
}
.mobile .order-card {
  line-height: 12px;
}
.mobile p.ratingtext {
  margin: 0;
}
.mobile .btnfilter svg {
  width: 8px;
  height: 8px;
}
.mobile .btn-address {
  line-height: 14px;
  width: 100%;
  font-size: medium;
  padding: 1rem 0;
}
.mobile .btn-address svg {
  width: 11px;
  height: 11px;
}
.mobile .profile-wrapper .field-group label {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.3rem;
}
.mobile .profile-wrapper .field-group {
  margin-bottom: 1rem;
}
.mobile .address-card,
.mobile .password-card,
.mobile .profile-info-card {
  border-radius: 5px;
}
.mobile .address-detail {
  font-size: 10px;
  line-height: 12px;
}
.mobile .editbtn {
  font-size: 12px;
  line-height: 14px;
}
.mobile .editbtn svg {
  height: 9px;
  width: 9px;
}
.mobile .items-totalprice span {
  font-size: 14px;
  line-height: 18px;
}
.mobile .cart-item-table tr td:first-child,
.mobile .p-sidebar-content tr td:first-child {
  width: 60%;
}
.mobile .cart-item-table tr #productimage,
.mobile .p-sidebar-content tr #productimage {
  flex: 0 0 54px;
  padding: 0;
}
.mobile .cart-item-table tr #productimage img,
.mobile .p-sidebar-content tr #productimage img {
  border-radius: 10px;
}
.mobile .cart-item-table tr th {
  font-size: 12px;
  line-height: 14px;
}
.mobile .cart-item-table tr td,
.mobile .cart-item-table tr th {
  padding-inline: 1rem;
}
.mobile .cart-slidesidebar .p-sidebar-content {
  background: #f6f5ff;
}
.mobile .cart-slidesidebar .item-counts {
  margin: 0 0 1rem;
  padding-inline: 0.5rem;
}
.mobile .socialwrap + p {
  margin-bottom: 0;
}
.mobile .product-list-rightwrap {
  width: calc(100% - 8rem);
  height: 100%;
}
.mobile .fliter-dropdown .filter-menuitem {
  font-size: 10px;
  line-height: 14px;
  padding-block: 0.2rem;
}
.mobile .filtermenu {
  min-width: auto;
}
.mobile .imageWrapper .leftarrow {
  left: 0;
}
.mobile .imageWrapper .rightarrow {
  right: 0;
}
.mobile .imageWrapper .leftarrow,
.mobile .imageWrapper .rightarrow {
  width: 24px;
  height: 24px;
  top: 45%;
}
.mobile .imageWrapper .leftarrow svg,
.mobile .imageWrapper .rightarrow svg {
  height: inherit;
}
.mobile .imageWrapper .img-wrap {
  width: 90vw;
  margin-inline: auto;
  background: transparent;
}
/* .mobile .imageWrapper .p-img {
    height: 200px;
} */
.mobile .p-sidebar-right .p-sidebar.cart-slidesidebar {
  height: calc(100vh - 80px);
  top: -41px;
}
.mobile .cart-innerwrap {
  min-height: calc(100vh - 260px);
}
.mobile .cartwrapper .itemwrap,
.mobile .cartwrapper .totalwrap {
  max-width: 100%;
  flex-basis: 100%;
}
.mobile .cartwrapper .itemwrap {
  margin-bottom: 0;
  padding-bottom: 0;
}
.mobile .cartwrapper .p-accordion-content .itemwrap {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  border-bottom: 1px solid #efeff1;
}
.mobile .cartwrapper .p-accordion-content .itemwrap:last-child {
  border-bottom: none;
}

.mobile .textwrap svg {
  width: 14px;
  height: 14px;
}
.mobile .btn-green {
  font-size: 14px;
  line-height: 17px;
  height: 55px;
}
.mobile.contact-us-container h6,
.mobile.help-support-container h6 {
  width: 100%;
}
.mobile.contact-us-container h6,
.mobile.help-support-container h6,
.mobile.orders-container .titleWrapper,
.mobileheader {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 1001;
  box-shadow: 0px 0px 10px 1px #cccccc;
  /* padding-bottom: 0; */
}
.mobile .mobile-header {
  flex: auto;
  display: flex;
  align-items: center;
}
.mobile.contact-us-container .wrap-contactus-page,
.mobile.help-support-container .wrap-helpsupport-page,
.mobile.orders-container,
.mobile {
  height: calc(100vh - 8rem);
  overflow: hidden;
}
.mobile.products-container .wrap-productlist {
  height: calc(100vh - 10.125rem);
  overflow: hidden;
}

.mobile.product-container .product-detail-page,
.mobile {
  height: calc(100vh - 8rem);
  overflow: scroll;
}

/* .mobile.home-container {
  margin-top: 8rem;
  margin-bottom: 4rem;
} */

.mobile.login-container {
  margin-top: 0;
  margin-bottom: 0;
}
.mobile.product-container .product-detail-page {
  padding-top: 0;
  /* margin-bottom: 4rem; */
}

.back-icon {
  padding: 0.2rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ececec;
  width: 10%;
  height: 42px;
  align-content: center;
  align-items: center;
  text-align: -webkit-center;
}
.mobile .search-box {
  width: 70%;
}
.mobile .title {
  width: 70%;
  align-content: center;
  padding-bottom: 0;
  font: caption;
}
.mobile.products-container #search-bar,
.mobile.product-container #search-bar {
  width: 70%;
}
.mobile.help-support-container .wrap-helpsupport-page,
.mobile.product-container .wholesalesection {
  margin-bottom: 82px;
}
.mobile.account-container {
  height: calc(100vh - 80px);
  background: #fff;
  /* padding: 1rem; */
}
.mobile .cart-slidesidebar.p-sidebar tr .price .text-black {
  font-size: 12px;
}
.mobile .cart-slidesidebar.p-sidebar tr .countBtn {
  height: 24px;
}
.mobile .tabtitle a {
  font-size: 10px;
  line-height: 12px;
  padding-inline: 5px;
}
.mobile .tabtitle a svg {
  width: 20px;
  height: 20px;
}
.mobile .parent-item .icon svg {
  height: 13px;
  width: 13px;
}
.mobile .breadcrumb svg {
  width: 10px;
  height: 10px;
}

.mobile .titleWrapper{
  display: none;
}

.mobile .p-popup .p-dialog-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.mobile .wrap-account-mobile {
  height: 100%;
  margin-top: 5rem;
}
.mobile #navbar-right {
  width: 20%;
}
.mobile .product-info-wrap {
  flex-direction: column;
  align-items: flex-start;
}
.mobile .product-info-wrap .btnwrap {
  margin-top: 5px;
}
.mobile .testimonials-section .btn {
  width: 28px;
  height: 28px;
  top: 62%;
}
.mobile .testimonials-section .embla__next {
  right: 0;
}
.mobile .p-breadcrumb {
  padding: 0;
  margin-block: 0.5rem;
}
.mobile #pagination-ribbon ul {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.mobile .logo-container {
  padding: 0;
  margin: auto auto 0.5rem;
}
.mobile .product-listing #product-image img {
  min-height: 120px;
}
.mobile .saler-card #product-image img {
  height: 120px;
}

.mobile .view-cart-container {
  margin-bottom: 4rem;
  padding-top: 2rem;
}

.mobile .wrapEditprofile {
  padding: 0;
}
.mobile .order-detail {
  margin-top: 0;
}
.mobile .faqwrapper,
.mobile .profile-pages,
.mobile .staticpage {
  margin-top: 0 !important;
  padding-top: 4rem;
}

.mobile .privacy-wrapper.staticpage,
.mobile .returnpolicy-wrapper.staticpage,
.mobile .contact-wrapper.staticpage,
.mobile .terms-wrapper.staticpage {
  padding-top: 0 !important;
}
.mobile .wrap-delivery-page {
  margin-bottom: 6rem;
}

.mobile .login-form {
  margin-bottom: 4rem;
}

.mobile .menu-item {
  min-width: 20%;
  width: 20%;
  text-align: center;
}
.mobile .cart-count {
  top: 1px;
}
.mobile .view-cart-container .p-accordion-header-text p {
  font-size: 16px;
  line-height: 20px;
}
.mobile .pay-method-wrap .paymethod {
  padding-block: 0.7rem;
}
.mobile .pay-method-wrap .paymethod span {
  font-size: 12px;
  line-height: 16px;
}
.mobile.login-container .login-bg .login-form {
  min-height: 30vh;
}
.mobile.login-container .login-form .messagewrapper {
  padding-top: 2.5rem;
}
.mobile .p-breadcrumb-list li:last-child .p-menuitem-text {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}